<style>
.settings-collapse {
  margin-bottom: 1rem;
}
.hard-border {
  border-radius: 0 !important;
}
@media (max-width: 1024px) {
  .sm-spacer {
    margin-top: .5rem;
  }
}
.btn-primary.pulse {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulsePrimary 1s infinite;
}

.btn-danger.pulse {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulseDanger 0.6s infinite;
}
.fadein {
	transition: opacity 5s ease;
}
</style>

<script>
import {VclCode} from 'vue-content-loading';
import CountryFlag from 'vue-country-flag'
import {HalfCircleSpinner} from 'epic-spinners';
import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";
import {Game} from "@/enums";

export default {
  props: ['server_id', 'options'],
  components: {
    VclCode,
    HalfCircleSpinner,
	  CountryFlag,
    RConHints: () => import('@/components/lazy/servers/view/desktop/rconhints/base.vue')
  },
  computed: {
    ...mapGetters([
      'getDTLocale'
    ]),
  },
  methods: {
    handleError: function (error) {
      console.error(`[ERROR]: ${error}`);
      this.error = true;
    },
    async getConfiguration() {
      try {
        const response = await fetch(
            process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/configuration`,
            { credentials: 'include' }
        );
        if (!response.ok) { throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);}
        const data = await response.json();
        this.configuration = data.configuration;
        this.gameserver = data.gameserver;
        this.ready = true;
        this.$nextTick(() => {
          this.configurationChanged = false;
        });
      } catch (error) {
        this.handleError(error);
      }
    },
    async verifyServer() {
      this.inProgress = true;
			if(this.clients) {
				Object.keys(this.clients).forEach((client_id) => {
					this.clients[client_id].connection = null;
				});
				this.clients.__ob__.dep.notify();
			}
      await new Promise(resolve => setTimeout(resolve, 500));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          general: this.configuration.general,
          gameserver: this.gameserver
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/rcon-test`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if (response.ok) {
	        let data = await response.json();
					this.edition = data.edition;
					this.host    = data.host;
					this.clients = data.clients;
	        this.clients.__ob__.dep.notify();
					this.test_id = data.test_id;
        } else {
	        this.inProgress = false;
	        this.clients = null;

          if (response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else if (response.status === 400) {
            this.connectionDetailsError = true;
            let data = await response.json();
            if (data.error === 'unknown-server') {
              this.$swal({
                icon: 'error',
                text: this.$t('server.view.configuration.rcon.error.unknown.text'),
                title: this.$t('server.view.configuration.rcon.error.unknown.title')
              });
            } else if (data.error === 'already-claimed') {
              this.$swal({
                icon: 'error',
                text: this.$t('server.view.configuration.rcon.error.claimed.text'),
                title: this.$t('server.view.configuration.rcon.error.claimed.title')
              });
            } else if (data.error === 'rcon-auth-error') {
              this.$swal({
                icon: 'error',
                text: this.$t('server.view.configuration.rcon.error.auth.text'),
                title: this.$t('server.view.configuration.rcon.error.auth.title')
              });
            } else if (data.error === 'rcon-socket-error') {
              this.$swal({
                icon: 'error',
                text: this.$t('server.view.configuration.rcon.error.socket.text'),
                title: this.$t('server.view.configuration.rcon.error.socket.title')
              });
            } else throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
    },
	  async saveConfiguration() {
		  try {
			  let acsrf_token = await get_acsrf_token();
			  let payload = {
				  acsrf_token: acsrf_token,
				  general: this.configuration.general,
				  gameserver: this.gameserver
			  };
			  let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/rcon-update`, {
				  method: 'POST',
				  body: JSON.stringify(payload),
				  credentials: 'include'
			  });
			  if (response.ok) {
				  this.$swal({
					  icon: 'success',
					  title: this.$t('server.view.configuration.save.success.title'),
					  text: this.$t('server.view.configuration.save.success.text'),
				  });
				  this.configurationChanged = null;
				  this.connectionDetailsError = false;
			  } else {
				  if (response.status === 429) {
					  this.$swal({
						  icon: 'warning',
						  text: this.$t('error.server.ratelimit.message'),
						  title: this.$t('error.server.ratelimit.title')
					  });
				  } else if (response.status === 400) {
					  this.connectionDetailsError = true;
					  let data = await response.json();
					  if (data.error === 'unknown-server') {
						  this.$swal({
							  icon: 'error',
							  text: this.$t('server.view.configuration.rcon.error.unknown.text'),
							  title: this.$t('server.view.configuration.rcon.error.unknown.title')
						  });
					  } else if (data.error === 'already-claimed') {
						  this.$swal({
							  icon: 'error',
							  text: this.$t('server.view.configuration.rcon.error.claimed.text'),
							  title: this.$t('server.view.configuration.rcon.error.claimed.title')
						  });
					  } else if (data.error === 'rcon-auth-error') {
						  this.$swal({
							  icon: 'error',
							  text: this.$t('server.view.configuration.rcon.error.auth.text'),
							  title: this.$t('server.view.configuration.rcon.error.auth.title')
						  });
					  } else if (data.error === 'rcon-socket-error') {
						  this.$swal({
							  icon: 'error',
							  text: this.$t('server.view.configuration.rcon.error.socket.text'),
							  title: this.$t('server.view.configuration.rcon.error.socket.title')
						  });
					  }
						else throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
				  }
			  }
		  } catch (error) {
			  console.log(`[ERROR] ${error}`);
			  this.$swal({
				  icon: 'error',
				  text: this.$t('error.server.generic.message')
			  });
		  }
	  },
	  async handleRConTestResults(results) {
		  // this.test_id may be not assigned if the backend responds to fast
			if(this.test_id === null) {
				this.$nextTick(async () => {
					await new Promise(resolve => setTimeout(resolve, 250));
					await this.handleRConTestResults(results);
				});
				return;
			}

		  if(results.test_id !== this.test_id) return;
			this.clients[results.origin].connection = results.connection;

			let clients = 0;
			let connectionAvailable = false;

			Object.keys(this.clients).forEach((client_id) => {
				let client = this.clients[client_id];
				if(client.connection) {
					clients++;
					if(client.connection.status === true) connectionAvailable = true;
				}
			});
		  this.clients.__ob__.dep.notify();

			if(clients === Object.keys(this.clients).length) {
				if(connectionAvailable) {
					await new Promise(resolve => setTimeout(resolve, 1000));
					await this.saveConfiguration();
					this.inProgress = false;
					this.test_id = null;
				} else {
					this.inProgress = false;
					this.connectionDetailsError = true;
					this.test_id = null;
				}
			}
	  }
  },
  mounted() {
    this.getConfiguration();
	  this.$socket.client.on('rcon:test:results', this.handleRConTestResults); // Update from server (Omega)
  },
	destroyed() {
		this.$socket.client.off('rcon:test:results', this.handleRConTestResults); // Update from server (Omega)
	},
  watch: {
    configuration: {
      deep: true,
      handler() {
        if (!this.ready) return;
        this.configurationChanged = this.configurationChanged !== null;
      }
    },
    'configuration.general.host'() {
      if (!this.configurationChanged) return;
      this.connectionDetailsChanged = true;
    },
    'configuration.general.port'() {
      if (!this.configurationChanged) return;
      this.connectionDetailsChanged = true;
    },
    'configuration.general.password'() {
      if (!this.configurationChanged) return;
      this.connectionDetailsChanged = true;
    }
  },
  data() {
    return {
      Game: Game,
      ready: false,
      error: false,
      inProgress: false,
      configurationChanged: null,
      connectionDetailsChanged: false,
      connectionDetailsError: false,
      configuration: {
      },
      gameserver: {},
	    test_id: null,
	    clients: null,
	    edition: null,
			host: null,
	    test_completion_timer: null
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-lg-8 col-sm-12 sm-spacer">
          <div>
            <div role="tablist">
              <!-- Connection details -->
              <b-card no-body class="hard-border">
                <a class="text-dark">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('server.view.configuration.rcon.title') }}
                    </h3>
                  </b-card-header>
                </a>
	              <b-card-body>
		              <b-card-text>
			              <!-- ---- -->
			              <div class="row">
				              <div class="col-lg-6 col-sm-12">
					              <h5>{{ $t('server.view.configuration.rcon.host') }}</h5>
					              <hr>
					              <b-input v-model="configuration.general.host" :value="gameserver.host" size="lg" type="text"
					                       class="form-control"/>
				              </div>
				              <div class="col-lg-6 col-sm-12">
					              <h5>{{ $t('server.view.configuration.rcon.game_port') }}</h5>
					              <hr>
					              <b-input v-model="gameserver.game_port" size="lg" type="number" class="form-control"/>
				              </div>
			              </div>
			              <div class="row mt-2">
				              <div class="col-lg-6 col-sm-12">
					              <h5>{{ $t('server.view.configuration.rcon.password') }}</h5>
					              <hr>
					              <b-input v-model="configuration.general.password" size="lg" type="text" class="form-control"/>
				              </div>
				              <div class="col-lg-6 col-sm-12">
					              <h5>{{ $t('server.view.configuration.rcon.rcon_port') }}</h5>
					              <hr>
					              <b-input v-model="configuration.general.port" size="lg" type="number" class="form-control"/>
				              </div>
			              </div>
			              <template v-if="test_id !== null || clients" class="fadein">
				              <div class="row mt-2">
					              <div class="col-lg-6 col-sm-12">
						              <div class="row" v-if="edition === 'free'">
							              <div class="col">
								              <div class="text-center">
									              <h6 class="m-0 p-0 text-uppercase text-warning">
										              {{ $t('server.view.configuration.rcon.free.description') }}
									              </h6>
								              </div>
							              </div>
						              </div>
						              <div class="row border rounded-pill p-3 mt-2 fadein" v-for="(client, client_id) in clients" :key="client_id">
							              <div class="col-4">
								              <div class="border-end ml-4">
									              <country-flag :country="client.country_code" size="big" />
								              </div>
							              </div>
							              <div class="col-8">
								              <template v-if="client.connection">
									              <template v-if="client.connection.status">
										              <div class="mt-2">
											              <span class="font-size-16 badge badge-success">
											                <i class="far fa-check-circle" />
												              <template v-if="client.connection.latency && client.connection.latency >= 1">
													              {{ $t('server.view.configuration.rcon.success.short') }}
													              <small class="font-size-12 ml-1">
													                {{ $n(client.connection.latency)}} ms
														              <i class="fal fa-wifi font-size-10" />
													              </small>
												              </template>
												              <template v-else>
													              {{ $t('server.view.configuration.rcon.success.short') }}
												              </template>
											              </span>

																		<!--
																		TODO: Unreliable, node patch required
											              <template v-if="client.connection.restricted">
												              <i class="fad fa-exclamation-triangle ml-2 text-warning font-size-20" v-b-tooltip.hover :title="$t('server.view.configuration.rcon.restricted.description')" />
											              </template>
											              -->
										              </div>
									              </template>
									              <template v-else>
										              <template v-if="client.connection.error_code === 'timeout'">
											              <div class="mt-2">
												              <span class="font-size-16 badge badge-danger ml-auto mr-auto">
												                <i class="far fa-exclamation-triangle" />
												                {{ $t('server.view.configuration.rcon.error.socket.short') }}
												              </span>
											              </div>
										              </template>
										              <template v-else-if="client.connection.error_code === 'password'">
											              <div class="mt-2">
																			<span class="font-size-16 badge badge-danger ml-auto mr-auto">
												                <i class="far fa-exclamation-triangle" />
												                {{ $t('server.view.configuration.rcon.error.auth.short') }}
												              </span>
											              </div>
										              </template>
										              <template v-else>
											              <div class="mt-2">
																			<span class="font-size-16 badge badge-danger ml-auto mr-auto">
												                <i class="far fa-exclamation-triangle" />
												                {{ $t('server.view.configuration.rcon.error.socket.short') }}
												              </span>
											              </div>
										              </template>
									              </template>
								              </template>
								              <template v-else>
									              <half-circle-spinner
											              v-if="inProgress"
											              :animation-duration="1200"
											              :size="32"
											              class="ml-auto mr-auto mt-1"
									              />
								              </template>
							              </div>
						              </div>
					              </div>
					              <div class="col-lg-6 col-sm-12">
						              <div class="mt-2">
							              <div>
								              <p class="text-muted mb-0 text-truncate text-uppercase">
									              Host
								              </p>
								              <h5 class="m-0 p-0">
									              <country-flag :country="host.info.country_code" size="small" v-if="host.info"/>
									              {{ host.ipv4 }}
									              <template v-if="host.info">
										              <small class="text-muted">
											              {{ host.info.asn }}
										              </small>
									              </template>
								              </h5>
							              </div>
							              <template v-if="host.info">
								              <div class="mt-2">
									              <p class="text-muted mb-0 text-truncate text-uppercase">
										              Network
									              </p>
									              <h5 class="m-0 p-0">
										              {{ host.info.provider }}
									              </h5>
								              </div>
								              <div class="mt-2" v-if="host.gsp.status">
									              <p class="text-muted mb-0 text-truncate text-uppercase">
										              Game Server Provider
									              </p>
									              <h5 class="m-0 p-0">
										              {{ host.gsp.name }}
									              </h5>
								              </div>
							              </template>
						              </div>
					              </div>
				              </div>
			              </template>
			              <div class="row mt-3">
				              <div class="col">
					              <button
							              class="btn btn-block"
							              v-on:click="verifyServer()"
							              :disabled="inProgress"
							              :class="{
                                'pulse': connectionDetailsChanged,
                                'disabled': inProgress,
                                'btn-primary': (connectionDetailsError === false),
                                'btn-danger': (connectionDetailsError === true)
                              }"
					              >
						              <half-circle-spinner
								              v-if="inProgress"
								              :animation-duration="1200"
								              :size="16"
								              class="align-middle d-inline-block"
						              />
						              <template v-if="!inProgress">
							              {{ $t('server.view.configuration.rcon.verify') }}
						              </template>
					              </button>
				              </div>
			              </div>
		              </b-card-text>
	              </b-card-body>
              </b-card>
              <!---------------------------------------->
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
	        <RConHints :game="options.game"></RConHints>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{ $t('error.server.generic.title') }}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{ $t('error.server.generic.component') }}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

